<template>
  <div class="apply reward-intro">
    <div class="container" v-if="$store.state.account.loggedIn">
      <div class="title font-xxl" :style="{backgroundImage: $route.query.mock === undefined ? 'url(/assets/img/page.apply.intro.reward.svg)' : 'url(/assets/img/page.apply.intro.mock.svg)'}">
        <div class="wrapper">
          <div>여러분의 아이디어를</div>
          <div><b>{{ $route.query.mock === undefined ? "후원형" : "모의" }} 크라우드펀딩</b>을 통해 {{ $route.query.mock === undefined ? "실현시켜 보세요." : "평가 받아보세요." }}</div>
        </div>
      </div>
      <div class="content">
        <div class="text" :style="{paddingRight: $route.query.mock === undefined ? '' : '40px'}">
          <p><b>오마이컴퍼니</b>는 더불어 사는 사회를 지향하고 더 나은 세상을 바꾸는 프로젝트를 대중들에게 소개하는 크라우드펀딩 플랫폼입니다. 오마이컴퍼니를 통해 여러분의 아이디어를 실현시킬 추진동력을 끌어 올려보세요.</p>
          <div>우선, 크라우드펀딩 프로젝트를 신청하기 전 진행자를 위한 가이드를 꼭 읽어주세요.
            크라우드펀딩 진행방식, 프로젝트 스토리 구성{{ $route.query.mock === undefined ? ", 리워드 설정" : "" }} 등 성공적인 프로젝트 신청을 위한 안내서가 준비되어 있습니다.
          </div>
          <a href="/omc/download/apply/omc_reward_uploadguide_2022080101.pdf" target="_blank" rel="noopener noreferrer" class="btn btn-bordered-secondary font-sm" :disabled="!state.loaded" v-if="$route.query.mock === undefined">
            <span>프로젝트 업로드 안내서 다운로드 </span>
            <i class="fa fa-download"></i>
          </a>
        </div>
        <div class="checklist" v-if="$route.query.mock === undefined">
          <div class="subject">
            <b>프로젝트 가이드를 읽어보셨나요?</b>
            <div>
              <span>오마이컴퍼니 프로젝트를 신청하기 전 다음 사항을 확인해주세요.</span>
            </div>
          </div>
          <ul class="tight font-sm">
            <li>
              <label :for="`${component.name}Check1`">
                <input type="checkbox" :id="`${component.name}Check1`" ref="registerCheck1Ref">
                <span class="text">프로젝트 소개자료, 리워드를 소개할 이미지나 동영상이 준비되셨나요?</span>
              </label>
            </li>
            <li>
              <label :for="`${component.name}Check2`">
                <input type="checkbox" :id="`${component.name}Check2`" ref="registerCheck2Ref">
                <span class="text">프로젝트 진행 일정 및 리워드 제공 시기 등 실행 계획은 준비되셨나요? </span>
              </label>
            </li>
            <li>
              <label :for="`${component.name}Check3`">
                <input type="checkbox" :id="`${component.name}Check3`" ref="registerCheck3Ref">
                <span class="text">진행 방식은 All Or Nothing(성공해야 리워드), Keep It All(무조건 리워드) 중 어떤 방식으로 진행할지 결정하셨나요?</span>
              </label>
            </li>
            <!--            <li>-->
            <!--              <label :for="`${component.name}Check4`" @click.prevent="openTerm('check4', $event)">-->
            <!--                <input type="checkbox" :id="`${component.name}Check4`" ref="registerCheck4Ref">-->
            <!--                <span class="text">펀딩 신청 전 필수 체크리스트</span>-->
            <!--              </label>-->
            <!--            </li>-->
          </ul>
        </div>
        <div class="contests" :class="{skeleton: !state.contest.loaded}" v-else>
          <div class="wrapper">
            <div class="subject">
              <b>파트너십 대회</b>
            </div>
            <div class="desc font-sm">
              <span v-if="computedContests.length">파트너십 대회를 선택해주세요.</span>
              <span v-else>현재 진행 중인 파트너십 대회(모의)가 없습니다.</span>
            </div>
            <div class="content">
              <ul class="tight">
                <li :class="{active: c.contestSeq === state.contest.contestSeq }" v-for="(c, idx) in computedContests" :key="idx" @click="select(c.contestSeq)">
                  <i class="fa fa-check-square-o" aria-hidden="true" v-if="c.contestSeq === state.contest.contestSeq"></i>
                  <i class="fa fa-square-o" aria-hidden="true" v-else></i>
                  <div>
                    <span>펀딩 기간: {{ $lib.getDateFormat(c.fundingStartDate, "yyyy-MM-dd") }} ~ {{ $lib.getDateFormat(c.fundingEndDate, "yyyy-MM-dd") }}</span>
                  </div>
                  <b>{{ c.contestName }}</b>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="actions">
        <button class="next btn btn-point" @click="apply()" :disabled="!state.loaded" v-if="$route.query.mock === undefined || computedContests.length">프로젝트 신청하기</button>
        <!--        <template v-if="$env.location !== 'prd' || ['MEMBER_MID_ADMIN', 'MEMBER_TOP_ADMIN'].includes($store.state.account.memberAuth)">-->
        <!--          <button class="ai btn btn-light" @click="apply(true)" :disabled="!state.loaded" v-if="$route.query.mock === undefined">-->
        <!--            <span>챗GPT로 쉽게 신청하기</span>-->
        <!--            <span class="badge badge-primary ml-1">Beta</span>-->
        <!--          </button>-->
        <!--        </template>-->
        <router-link to="/apply/rewardIntro?mock" class="btn btn-light mock" v-if="$route.query.mock === undefined">모의 프로젝트 신청하기</router-link>
      </div>
    </div>
    <NoLogin v-else-if="$store.state.account.checked"/>
  </div>
</template>

<script>
import {computed, defineComponent, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import router from "@/scripts/router";
import mixin from "@/scripts/mixin";
import NoLogin from "@/components/NoLogin";

function Component(initialize) {
  this.name = "pageApplyRewardIntro";
  this.initialize = initialize;
}

export default defineComponent({
  components: {NoLogin},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.dispatch("setDocumentTitle", "프로젝트 신청");

      if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }

      for (let i = 0; i < 3; i += 1) {
        state.contest.list.push({
          fundingStartDate: "20220101",
          fundingEndDate: "20230101",
          contestSeq: "",
          contestName: "Please wait a moment",
          contestType: "S"
        });
      }

      state.contest.loaded = false;
      http.get("/api/partnerships/contests", {contestType: "S"}).then(({data}) => {
        state.contest.loaded = true;
        state.contest.list = data.body;
      });
    });

    const state = reactive({
      loaded: true,
      contest: {
        loaded: false,
        contestSeq: "",
        list: []
      }
    });

    const registerCheck1Ref = ref();
    const registerCheck2Ref = ref();
    const registerCheck3Ref = ref();
    const registerCheck4Ref = ref();

    const args = {
      partnershipFlag: "N",
      simulationFlag: "N",
      contestSeq: null,
    };

    const computedContests = computed(() => {
      return state.contest.list.filter(c => c.contestType === "S");
    });

    const select = (contestSeq) => {
      state.contest.contestSeq = contestSeq;
    };

    const create = () => {
      http.post("/api/reward/projects", args).then(({data}) => {
        router.push({path: `/apply/${data.body.projectSeq}`});
      });
    };

    const apply = (ai) => {
      if (router.app.$route.query.mock === undefined && (!registerCheck1Ref.value.checked || !registerCheck2Ref.value.checked || !registerCheck3Ref.value.checked)) {
        registerCheck1Ref.value.nextSibling.style.color = registerCheck1Ref.value.checked ? "inherit" : "red";
        registerCheck2Ref.value.nextSibling.style.color = registerCheck2Ref.value.checked ? "inherit" : "red";
        registerCheck3Ref.value.nextSibling.style.color = registerCheck3Ref.value.checked ? "inherit" : "red";
        // registerCheck4Ref.value.nextSibling.style.color = registerCheck4Ref.value.checked ? "inherit" : "red";
        return store.commit("setSwingMessage", "프로젝트 신청 전 준비사항을 체크해주세요.");
      }

      if (ai) {
        return store.commit("openModal", {
          name: "ChatGPT",
          params: {
            page: "intro"
          }
        });
      }

      if (router.app.$route.query.mock !== undefined && !state.contest.contestSeq) {
        return store.commit("setSwingMessage", "파트너십 대회를 선택해주세요.");
      }

      if (router.app.$route.query.mock !== undefined) {
        args.partnershipFlag = "Y";
        args.simulationFlag = "Y";
        args.contestSeq = state.contest.contestSeq;
      } else {
        args.partnershipFlag = "N";
        args.simulationFlag = "N";
        args.contestSeq = null;
      }

      state.loaded = false;
      http.get("/api/reward/projects/in-progress", args, {cache: true}).then(({data}) => {
        state.loaded = true;

        if (data.body.length) {
          store.commit("setSwingMessage", "현재 작성 중인 프로젝트가 있습니다.");
          store.commit("openModal", {
            name: "Temporaries",
            params: {
              partnershipFlag: args.partnershipFlag,
              simulationFlag: args.simulationFlag,
              contestSeq: args.contestSeq,
            },
            callback: `${component.name}.create`
          });
        } else {
          create();
        }
      });
    };

    const checking = () => {
      registerCheck4Ref.value.checked = true;
    };

    const openTerm = (name) => {
      registerCheck4Ref.value.checked = false;
      store.commit("openModal", {
        name: "Terms",
        params: getTermContent(name),
        callback: `${component.name}.checking`
      });
    };

    const getTermContent = () => {
      return [
        {
          title: "오프라인 맛집이 우리집 식탁에? 10분 만에 완성하는 요리 한 상",
          content: "이제는 집에서도 간편하게 레스토랑의 맛을 즐겨보세요. 손님 초대 요리로 완.전.추.천"
        },
        {
          title: "려움을 잘 알고 있습니다. 이번 크라우드펀딩으로 모인 후원금으상",
          content: "청년 농업인이 모여 만든 ‘맑게 갠 정원’은 누구보다 청년 창업의 어려움을 잘 알고 있습니다. 이번 크라우드펀딩으로 모인 후원금으로 미래 청년 농업인을 육성하고 그들에게 정원 일부를 임대할 예정입니다. 이 프로그램을 통해 초기 창업 비용에 대한 부담을 줄이고, 미래 농업인으로 성장할 수 있는 발판을 마련하고자 합니다."
        },
        {
          title: "오프라인 맛집이 우리집 식탁에? 10분 만에 완성하는 요리 한 상",
          content: "관련된 기획전 등을 통해 신청함으로써 상세 페이지 제작을 지원받으시는 분"
        }
      ];
    };

    return {component, state, registerCheck1Ref, registerCheck2Ref, registerCheck3Ref, registerCheck4Ref, computedContests, checking, select, apply, create, openTerm, getTermContent};
  }
});
</script>

<style lang="scss" scoped>
.reward-intro {
  > .container {
    > .title {
      background-size: cover;
      padding: $px25;
      width: 100%;
      color: #fff;
      height: $px208;
      text-align: center;
      margin: $px15 0;
      display: table;

      > .wrapper {
        display: table-cell;
        vertical-align: middle;
      }
    }

    > .content {
      > .text {
        padding: $px40 $px370 $px40 $px40;
        background-color: #e9ecef;
        border-radius: $px4;
        position: relative;

        a {
          height: $formHeightLg;
          line-height: calc(#{$formHeightLg} - 2px);
          padding: 0 $px20;
          position: absolute;
          top: 50%;
          right: $px50;
          margin-top: $px-25;

          i {
            margin-left: $px8;
          }
        }
      }

      .checklist {
        padding: $px40;

        .subject {
          margin-left: $px-2;

          div {
            margin-top: $px5;
          }
        }

        ul {
          margin-top: $px20;

          li {
            input[type=checkbox], span {
              vertical-align: middle;
            }

            input[type=checkbox] {
              margin-right: $px8;

              &:checked + span {
                color: inherit !important;
              }
            }
          }
        }
      }

      .contests {
        > .wrapper {
          background: #fff;
          padding: $px40;

          .subject {
            margin-bottom: $px9;
          }

          .desc {
            margin-bottom: $px25;
          }

          .content {
            > ul > li {
              padding: $px15 $px15 $px15 $px53;
              border: $px1 solid #eee;
              cursor: pointer;
              background: $colorBackground;
              border-radius: $px4;
              position: relative;

              &:not(:last-child) {
                margin-bottom: $px10;
              }

              > i {
                position: absolute;
                top: 50%;
                left: $px18;
                font-size: $px20;
                transform: translateY(-50%);
              }

              > div {
                font-size: $px13;
              }

              > b {
                font-size: $px14;
              }

              &:hover {
                background: #eee;
              }

              &.active {
                border-color: $colorPoint;
                color: $colorPoint;
                font-weight: 500;
                background: #fff;

                > i {
                  opacity: 1;
                }
              }
            }
          }
        }

        .action {
          .btn {
            padding: $px15;
            height: $px56;
            border-radius: 0;

            span {
              vertical-align: middle;

              &.img {
                width: $px24;
                height: $px24;
                margin-left: $px8;
              }
            }
          }
        }

        &.skeleton {
          > .wrapper {
            .subject > b {
              @include skeleton;
            }

            .desc > span {
              @include skeleton;
            }

            .content > ul > li {
              @include skeleton;

              span, b {
                @include skeleton;
              }
            }
          }
        }
      }
    }

    .actions {
      padding-bottom: $px40;
      text-align: center;
      position: relative;

      .btn {
        padding: $px19 $px24;

        &.prev {
          margin-right: $px15;
        }

        &.mock {
          margin-left: $px15;
        }

        &.ai {
          margin-left: $px15;
        }
      }
    }
  }

  @media(max-width: 991px) {
    > .container {
      .content {
        .text {
          padding-right: $px40;

          a {
            position: static;
            margin-top: $px25;
          }
        }

        .checklist {
          padding: $px40 $px20;
        }

        .contests > .wrapper {
          padding-left: $px20;
          padding-right: $px20;
        }
      }

      .actions {
        .btn {
          width: 100% !important;
          margin: 0 !important;

          &.next {
            margin-bottom: $px15 !important;
          }
        }
      }
    }
  }
}
</style>